import apiService from '../services/api-service';
import { useAuth } from '../providers/auth-provider';
import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function useUserApi() {
  const { logOut, refreshToken } = useAuth();
  const { post } = useAuthenticatedRequest();
  const USERS_API_BASE_PATH = '/api/users';

  return {
    registerUser: function (data, profileId) {
      const path = `${USERS_API_BASE_PATH}/register-user`;
      const payload = {
        profileId: profileId,
        email: data.email,
        pwd: data.pwd,
      };
      return apiService.post(path, payload);
    },

    logInUser: function (payload) {
      const path = `${USERS_API_BASE_PATH}/log-in`;
      return apiService.post(path, payload);
    },

    requestPasswordReset: function (payload) {
      const path = `${USERS_API_BASE_PATH}/reset-password`;
      return apiService.post(path, payload);
    },

    changePassword: function (newPwd, resetToken) {
      const path = `${USERS_API_BASE_PATH}/change-password`;

      const payload = {
        pwd: newPwd,
        resetToken: resetToken,
      };
      return apiService.post(path, payload);
    },

    logOutUser: function () {
      const token = refreshToken?.token;
      logOut();

      if (!token) return;

      return post(`${USERS_API_BASE_PATH}/log-out`, JSON.stringify(token));
    },
  };
}
